export var Industry;
(function(Industry) {
  Industry.Energy = 'Energy';
  Industry.Media = 'Media';
  Industry.ECommerce = 'E-commerce';
  Industry.Travel = 'Travel';
  Industry.SAAS = 'SaaS';
  Industry.ITServices = 'IT Services';
  Industry.B2bServices = 'B2B Services';
  Industry.HealthAndBeauty = 'Health & Beauty';
  Industry.ProductsWithMission = 'Products with mission';
})(Industry || (Industry = {}));

export var Service;
(function(Service) {
  Service.FCPD = 'Full Cycle Product Development';
  Service.DevTeam = 'Development Team';
  Service.DigitalTransformation = 'Digital Transformation';
  Service.CTOAsAService = 'CTO as a Service';
  Service.PDD = 'Product Design and Development';
  Service.MachineLearning = 'Machine Learning';
})(Service || (Service = {}));

export var ProductStage;
(function(ProductStage) {
  ProductStage.Prototype = 'Prototype';
  ProductStage.MVP = 'MVP';
  ProductStage.PMF = 'Product/Market Fit';
  ProductStage.Scaling = 'Scaling';
})(ProductStage || (ProductStage = {}));
export var Region;

(function(Region) {
  Region.Europe = 'Europe';
  Region.MiddleEastAndAfrica = 'Middle East & Africa';
  Region.Asia = 'Asia';
  Region.NorthAmerica = 'North America';
})(Region || (Region = {}));
export var Country;

(function(Country) {
  Country.Europe = 'Europe';
  Country.Poland = 'Poland';
  Country.Germany = 'Germany';
  Country.Italy = 'Italy';
  Country.Ireland = 'Ireland';
  Country.Switzerland = 'Switzerland';
  Country.France = 'France';
  Country.Sweden = 'Sweden';
  Country.UnitedKingdom = 'United Kingdom';
  Country.Canada = 'Canada';
  Country.UnitedStates = 'United States';
  Country.HongKong = 'Hong Kong';
  Country.SaudiArabia = 'Saudi Arabia';
  Country.Israel = 'Israel';
  Country.Oman = 'Oman';
  Country.UnitedArabEmirates = 'United Arab Emirates';
  Country.Netherlands = 'The Netherlands';
  Country.Finland = 'Finland';
})(Country || (Country = {}));

export const CASE_STUDIES_COUNTRY_REGION_MAP = {
  [Country.Europe]: Region.Europe,
  [Country.France]: Region.Europe,
  [Country.Germany]: Region.Europe,
  [Country.Italy]: Region.Europe,
  [Country.Ireland]: Region.Europe,
  [Country.Switzerland]: Region.Europe,
  [Country.Poland]: Region.Europe,
  [Country.UnitedKingdom]: Region.Europe,
  [Country.Sweden]: Region.Europe,
  [Country.Netherlands]: Region.Europe,
  [Country.Canada]: Region.NorthAmerica,
  [Country.UnitedStates]: Region.NorthAmerica,
  [Country.HongKong]: Region.Asia,
  [Country.Israel]: Region.MiddleEastAndAfrica,
  [Country.Oman]: Region.MiddleEastAndAfrica,
  [Country.SaudiArabia]: Region.MiddleEastAndAfrica,
  [Country.UnitedArabEmirates]: Region.MiddleEastAndAfrica,
  [Country.Finland]: Region.Europe,
};

export const CONTINENT_CASE_STUDIES_REGION_MAP = {
  AF: Region.MiddleEastAndAfrica,
  AS: Region.Asia,
  EU: Region.Europe,
  NA: Region.NorthAmerica,
  OC: Region.NorthAmerica,
  SA: Region.NorthAmerica,
};
